// Packages
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// Components
import App from 'App';
// Redux
import store from 'redux/store';
// Styles
import 'antd/dist/antd.css';
import 'styles/main.scss';

if (process.env.NODE_ENV === 'production') {
  console.warn = () => {}; // Disable warnings
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
