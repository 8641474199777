// Core
import { useEffect, useState } from 'react';
// Components
import { ActionModal, Loader } from 'components';
import SwitchAccount from './SwitchAccount';
// Assets
import { MiniLogo } from 'assets/icons/logo';
// Redux
import { STORE_KEYS, useAppSelector } from 'redux/store';
// CustomHooks
import { useFetch } from 'customHooks';
// API
import { CompaniesAPI } from 'api/endpoints';
// Types and interfaces
import { IGetSettingsRes } from 'types/companyTypes';
import { IAccount } from 'types/userTypes';
// I18n
import { t } from 'i18n';

interface IProps {
  isModalVisible: boolean;
  availableAccounts: IAccount[] | null;
  onCloseModal: () => void;
}

const SwitchAccountModal = ({ isModalVisible, availableAccounts, onCloseModal }: IProps) => {
  const { user } = useAppSelector(STORE_KEYS.USER);

  const [isLoading, setIsLoading] = useState(false);

  // Get company settings
  const [
    { response: responseGetCompanySettings },
    doFetchGetCompanySettings,
    doResetGetCompanySettings,
  ] = useFetch<IGetSettingsRes, void, typeof CompaniesAPI.getCompanySettings>(
    CompaniesAPI.getCompanySettings,
  );

  useEffect(() => {
    if (!isModalVisible) {
      return;
    }

    doFetchGetCompanySettings();

    return () => {
      doResetGetCompanySettings();
    };
  }, [isModalVisible, doFetchGetCompanySettings, doResetGetCompanySettings]);

  if (isLoading) {
    return <Loader isLoading={isLoading} />;
  }

  return (
    <ActionModal
      destroyOnClose
      footer={null}
      title={
        <>
          <MiniLogo />
          {t('settingsModal.switchAccount')}
        </>
      }
      visible={isModalVisible}
      onCancel={onCloseModal}
    >
      <SwitchAccount
        isVerified={!!user?.emailVerified}
        id={responseGetCompanySettings?.main?.id}
        availableAccounts={availableAccounts}
        onCloseModal={onCloseModal}
        onSetLoading={setIsLoading}
      />
    </ActionModal>
  );
};

export default SwitchAccountModal;
