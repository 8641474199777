// Core
import { useEffect } from 'react';
// API
import { UserAPI } from 'api/endpoints';
// Custom Hooks
import { useFetch } from 'customHooks';
// Types
import { IGetAccountRes } from 'types/userTypes';
import { STORE_KEYS, useAppSelector } from 'redux/store';
import { ROLES } from 'helpers/data/constants';

const useGetClientAccounts = () => {
  const { user: currentUser } = useAppSelector(STORE_KEYS.USER);

  const isCurrentUserAdmin = currentUser?.role === ROLES.business_admin;

  // Getting available accounts
  const [{ response: availableAccounts }, doFetchAvailableAccounts, doResetAvailableAccounts] =
    useFetch<IGetAccountRes, void, typeof UserAPI.getAdminAccounts>(UserAPI.getAdminAccounts);

  useEffect(() => {
    isCurrentUserAdmin && doFetchAvailableAccounts();

    return () => {
      doResetAvailableAccounts();
    };
  }, [isCurrentUserAdmin, doFetchAvailableAccounts, doResetAvailableAccounts]);

  return {
    availableAccounts: availableAccounts?.items || null,
    doFetchAvailableAccounts,
  };
};

export default useGetClientAccounts;
