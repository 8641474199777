// Packages
import { AxiosError } from 'axios';
// Modules
import { getErrorTranslation } from './errorTranslator';
// Helpers
import { NOT_FOUND } from 'helpers/data/constants';
import showNotification from 'helpers/showNotification';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';

export enum ERROR_STATUSES {
  SUBSCRIPTION_NOT_FOUND = 402,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PERMISSION_DENIED = 403,
  NOT_FOUND = 404,
  NOT_ACCEPTABLE = 406,
  CONFLICT = 409,
  PAYLOAD_TOO_LARGE = 413,
  INTERNAL_SERVER_ERROR = 500,
  TO_MANY_REQUEST = 429,
}

const errorHandler = {
  observe(error: AxiosError) {
    if (error?.message === 'Request aborted') {
      return;
    }

    const errorData = error?.response?.data as IErrorRes;
    const errorStatus = error.response?.status || ERROR_STATUSES.INTERNAL_SERVER_ERROR;

    const { errors, message } = getErrorTranslation(errorData);

    const errorMessage = message ?? error?.message;
    let errorPayload = { message: errorMessage, errors };

    if (errorData?.data) {
      errorPayload = { message: errorMessage, data: errorData?.data } as any;
    }

    switch (errorStatus) {
      case ERROR_STATUSES.SUBSCRIPTION_NOT_FOUND: {
        message && showNotification('error', errorMessage);

        return Promise.reject<typeof errorPayload>(errorPayload);
      }

      case ERROR_STATUSES.TO_MANY_REQUEST: {
        message && showNotification('error', errorMessage);

        return Promise.reject<typeof errorPayload>({
          message: errorMessage,
          retryTime: error?.response?.headers['retry-after'],
        });
      }

      case ERROR_STATUSES.BAD_REQUEST: {
        return Promise.reject<typeof errorPayload>(errorPayload);
      }

      case ERROR_STATUSES.UNAUTHORIZED: {
        return;
      }

      case ERROR_STATUSES.PERMISSION_DENIED: {
        if (error?.response?.data?.message !== 'EMAIL_NOT_VERIFIED') {
          showNotification('error', errorMessage);
        }

        return Promise.reject<typeof errorPayload>(errorPayload);
      }

      case ERROR_STATUSES.NOT_FOUND: {
        showNotification('error', errorMessage);

        return Promise.reject<typeof errorPayload>({
          ...errorPayload,
          errorKey: errorData?.message,
        });
      }

      case ERROR_STATUSES.NOT_ACCEPTABLE: {
        message && showNotification('error', errorMessage);

        return Promise.reject<typeof errorPayload>(errorPayload);
      }

      case ERROR_STATUSES.CONFLICT: {
        message && showNotification('error', errorMessage);

        return Promise.reject<typeof errorPayload>(errorPayload);
      }

      case ERROR_STATUSES.PAYLOAD_TOO_LARGE: {
        showNotification('error', errorMessage);

        return Promise.reject<typeof errorPayload>(errorPayload);
      }

      case ERROR_STATUSES.INTERNAL_SERVER_ERROR: {
        showNotification('error', errorMessage);

        return Promise.reject<typeof errorPayload>(errorPayload);
      }

      default: {
        showNotification('error', errorMessage);

        return Promise.reject<AxiosError>(error);
      }
    }
  },
  checkIfNotFound(error: IErrorRes | null) {
    return error?.errorKey ? NOT_FOUND.includes(error.errorKey) : false;
  },
};

export default errorHandler;
