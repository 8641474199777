// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import {
  IShiftParams,
  ITimeEntryParams,
  TGetShiftsRes,
  ICreateShiftRes,
  ICreateShiftBody,
  IUpdateShiftRes,
  IUpdateShiftBody,
  IDeleteShiftRes,
  ICopyShiftRes,
  ICopyShiftBody,
  IGetShiftDetailsRes,
  ICopyWeekShiftsBody,
  ICopyWeekShiftsRes,
  INotifyEmployeesBody,
  INotifyEmployeesRes,
  TGetTimeEntriesRes,
  TGetTimeEntryRes,
  IUpdateTimeEntryBody,
  IUpdateTimeEntryRes,
  ICreateTimeEntryBody,
  ICreateTimeEntryRes,
  IDeleteTimeEntryParams,
  IDeleteTimeEntryRes,
  IPatchShiftParamsWithBody,
  IDeleteShiftsBody,
  IUpdateEmployeesInShifts,
  IClockOutParams,
  ITimeEntryLogRes,
  IEmptyJobsForPeriod,
  TGetNotNotifyShiftsRes,
  TGetShiftForCopyItem,
  IGetScheduleDailyRes,
} from 'types/scheduleTypes';
import { TQueryParams, IResMessage } from 'types/appTypes';

export const getShifts = (basicParams: TQueryParams) => {
  // IncludeTotals: 'true' - must be in params for correct response with Totals!
  const params = { ...basicParams, includeTotals: 'true' };
  return xhr.get<TGetShiftsRes, TGetShiftsRes>(`${ENDPOINTS.schedule}`, { params });
};

export const getShiftsForCopy = (params: TQueryParams) => {
  return xhr.get<TGetShiftForCopyItem[], TGetShiftForCopyItem[]>(`${ENDPOINTS.schedule}`, {
    params,
  });
};

export const createShift = ({ jobId, ...body }: ICreateShiftBody) => {
  return xhr.post<ICreateShiftRes, ICreateShiftRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.schedule}`,
    body,
  );
};

export const getShiftDetails = ({ jobId, shiftId }: IShiftParams) => {
  return xhr.get<IGetShiftDetailsRes, IGetShiftDetailsRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.schedule}/${shiftId}`,
  );
};

export const updateShift = ({ jobId, shiftId, ...body }: IUpdateShiftBody) => {
  return xhr.put<IUpdateShiftRes, IUpdateShiftRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.schedule}/${shiftId}`,
    body,
  );
};

export const updateShiftBySeparateDetails = ({
  jobId,
  shiftId,
  ...body
}: IPatchShiftParamsWithBody) => {
  return xhr.patch<IUpdateShiftRes, IUpdateShiftRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.schedule}/${shiftId}`,
    body,
  );
};

export const deleteShift = ({ jobId, shiftId }: IShiftParams) => {
  return xhr.delete<IDeleteShiftRes, IDeleteShiftRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.schedule}/${shiftId}`,
  );
};

export const copyShiftToOtherDays = ({ jobId, shiftId, ...body }: ICopyShiftBody) => {
  return xhr.post<ICopyShiftRes, ICopyShiftRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.schedule}/${shiftId}${ENDPOINTS.copyShift}`,
    body,
  );
};

export const copyWeekShifts = (body: ICopyWeekShiftsBody) => {
  return xhr.post<ICopyWeekShiftsRes, ICopyWeekShiftsRes>(
    `${ENDPOINTS.schedule}${ENDPOINTS.copyShift}`,
    body,
  );
};

export const notifyEmployees = (body: INotifyEmployeesBody) => {
  return xhr.post<INotifyEmployeesRes, INotifyEmployeesRes>(
    `${ENDPOINTS.schedule}${ENDPOINTS.notify}`,
    body,
  );
};

export const getTimeEntries = ({ jobId, shiftId }: IShiftParams) => {
  return xhr.get<TGetTimeEntriesRes, TGetTimeEntriesRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.schedule}/${shiftId}${ENDPOINTS.timeEntries}`,
  );
};

export const createTimeEntry = ({ jobId, shiftId, ...body }: ICreateTimeEntryBody) => {
  return xhr.post<ICreateTimeEntryRes, ICreateTimeEntryRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.schedule}/${shiftId}${ENDPOINTS.timeEntries}`,
    body,
  );
};

export const getTimeEntryDetails = ({ jobId, shiftId, timeEntryId }: ITimeEntryParams) => {
  return xhr.get<TGetTimeEntryRes, TGetTimeEntryRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.schedule}/${shiftId}${ENDPOINTS.timeEntries}/${timeEntryId}`,
  );
};

export const updateTimeEntryDetails = ({
  jobId,
  shiftId,
  timeEntryId,
  ...body
}: IUpdateTimeEntryBody) => {
  return xhr.patch<IUpdateTimeEntryRes, IUpdateTimeEntryRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.schedule}/${shiftId}${ENDPOINTS.timeEntries}/${timeEntryId}`,
    body,
  );
};

export const deleteTimeEntry = ({ jobId, shiftId, timeEntryId }: IDeleteTimeEntryParams) => {
  return xhr.delete<IDeleteTimeEntryRes, IDeleteTimeEntryRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.schedule}/${shiftId}${ENDPOINTS.timeEntries}/${timeEntryId}`,
  );
};

export const deleteShifts = (body: IDeleteShiftsBody) => {
  return xhr.delete<IResMessage, IResMessage>(`${ENDPOINTS.schedule}`, { data: body });
};

export const logLateCallOff = (shiftId: number) => {
  return xhr.post<IResMessage, IResMessage>(
    `${ENDPOINTS.schedule}/${shiftId}${ENDPOINTS.perfLogs}${ENDPOINTS.lateCallOff}`,
  );
};

export const logNoShow = (shiftId: number) => {
  return xhr.post<IResMessage, IResMessage>(
    `${ENDPOINTS.schedule}/${shiftId}${ENDPOINTS.perfLogs}${ENDPOINTS.noShow}`,
  );
};

export const updateEmployeesInShifts = (body: IUpdateEmployeesInShifts) => {
  return xhr.patch<TGetShiftsRes, TGetShiftsRes>(`${ENDPOINTS.schedule}`, body);
};

export const clockOut = ({ jobId, shiftId, timeEntryId }: IClockOutParams) => {
  return xhr.post<IResMessage, IResMessage>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.schedule}/${shiftId}${ENDPOINTS.timeEntries}/${timeEntryId}${ENDPOINTS.clockOut}`,
  );
};

export const getActualHoursHistory = ({ jobId, shiftId }: IShiftParams) => {
  return xhr.get<ITimeEntryLogRes, ITimeEntryLogRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.schedule}/${shiftId}${ENDPOINTS.timeEntriesLogs}`,
  );
};

export const getEmptyJobsFoSomePeriod = (params: TQueryParams) => {
  return xhr.get<IEmptyJobsForPeriod, IEmptyJobsForPeriod>(
    `${ENDPOINTS.emptyJobForSchedule}`,
    {
      params,
    },
  );
};

export const getNotNotifyShifts = (params: { dateFrom: string }) => {
  return xhr.get<TGetNotNotifyShiftsRes, TGetNotNotifyShiftsRes>(
    `${ENDPOINTS.schedule}${ENDPOINTS.notNotify}`,
    { params },
  );
};

export const getDailySchedule = (params: TQueryParams) => {
  return xhr.get<IGetScheduleDailyRes, IGetScheduleDailyRes>(`${ENDPOINTS.dailySchedule}`, {
    params,
  });
};
