// Core
import { useState } from 'react';
// Packages
import { Drawer } from 'antd';
import { CgMenuGridO } from 'react-icons/cg';
// Components
import AuthMenu from '../AuthMenu';
import { Navigation } from 'components';
import { Button, Divider } from 'components/lib';
// I18n
import { t } from 'i18n';
// Styles
import styles from './mobileMenu.module.scss';

interface IProps {
  isMultipleAccounts: boolean;
  onSetIsSettingsModalVisible: () => void;
  doFetchAvailableAccounts: () => void;
  onSwitchAccountModal: () => void;
}

const MobileMenu = ({
  isMultipleAccounts,
  onSetIsSettingsModalVisible,
  doFetchAvailableAccounts,
  onSwitchAccountModal,
}: IProps) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const handleToggleMenu = () => setIsMenuVisible(prevState => !prevState);

  return (
    <>
      <Button
        icon={<CgMenuGridO size={24} />}
        onClick={handleToggleMenu}
        className={styles.menuBtn}
      />

      <Drawer
        width="80%"
        placement="right"
        title={t('header.menu')}
        visible={isMenuVisible}
        onClose={handleToggleMenu}
        className={styles.sidebarMenu}
      >
        <AuthMenu
          isMultipleAccounts={isMultipleAccounts}
          onSetIsSettingsModalVisible={onSetIsSettingsModalVisible}
          onToggleMenu={handleToggleMenu}
          onSwitchAccountModal={onSwitchAccountModal}
          doFetchAvailableAccounts={doFetchAvailableAccounts}
        />

        <Divider className={styles.divider} />

        <Navigation
          isMenuVisible={isMenuVisible}
          mode="vertical"
          onToggleMenu={handleToggleMenu}
        />
      </Drawer>
    </>
  );
};

export default MobileMenu;
