const isLocal = process.env.NODE_ENV === 'development';
const isStage = /staging/.test(document.location.hostname);

const localCfg = {
  siteUrl: 'http://localhost:3000',
  apiUrl: 'https://staging-api.timego.com',
  adminUrl: 'http://localhost:3001',
};

export const stageCfg = {
  siteUrl: 'https://staging-app.timego.com',
  apiUrl: 'https://staging-api.timego.com',
  adminUrl: 'https://staging-admin.timego.com',
};

export const prodCfg = {
  siteUrl: 'https://app.timego.com',
  apiUrl: 'https://api.timego.com',
  adminUrl: 'https://admin.timego.com',
};

const getCurrentConfig = () => {
  if (isLocal) {
    return localCfg;
  }

  if (isStage) {
    return stageCfg;
  }

  return prodCfg;
};

const { apiUrl, siteUrl, adminUrl } = getCurrentConfig();

export { apiUrl, siteUrl, adminUrl };
