// Packages
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Redux
import {
  getEmptyJobsForSomePeriodAsync,
  getShiftsAsync,
  createShiftAsync,
  getShiftDetailsAsync,
  updateShiftAsync,
  deleteShiftAsync,
  copyShiftToOtherDaysAsync,
  copyWeekShiftsAsync,
  getShiftsForNotifyAsync,
  notifyEmployeesAsync,
  getShiftTimeEntriesAsync,
  deleteShiftsAsync,
  getShiftsForCopyAsync,
  getDailyScheduleAsync,
} from './scheduleThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import {
  TShiftType,
  ShiftTypeEnum,
  IGetShiftDetailsRes,
  IDeleteShiftsError,
  IGetShiftItemUpdateStatusWebSocket,
  IEmptyJobsForPeriod,
  IShiftItem,
  IShiftTotals,
  TGetShiftForCopyItem,
  IGetScheduleDailyRes,
} from 'types/scheduleTypes';
import { IErrorRes } from 'types/appTypes';
import { TSliceReducer, TActionType } from 'redux/store';

type TAction<T extends TActionType> =
  | (typeof getShiftsAsync)[T]
  | (typeof createShiftAsync)[T]
  | (typeof getShiftDetailsAsync)[T]
  | (typeof updateShiftAsync)[T]
  | (typeof deleteShiftAsync)[T]
  | (typeof copyShiftToOtherDaysAsync)[T]
  | (typeof copyWeekShiftsAsync)[T]
  | (typeof notifyEmployeesAsync)[T]
  | (typeof getShiftTimeEntriesAsync)[T]
  | (typeof deleteShiftsAsync)[T]
  | (typeof getEmptyJobsForSomePeriodAsync)[T]
  | (typeof getDailyScheduleAsync)[T];

const handleLoadingReducer: TSliceReducer<IState, TAction<'pending'>> = state => {
  state.isLoading = true;
  state.error = null;
};

const handleEmptyFulfilledReducer: TSliceReducer<IState, TAction<'fulfilled'>> = state => {
  state.isLoading = false;
};

const handleErrorReducer: TSliceReducer<IState, TAction<'rejected'>> = (state, action) => {
  state.isLoading = false;
  state.error = action.payload || null;
};

interface IState {
  shifts: IShiftItem[] | null;
  totals: IShiftTotals | null;
  shiftDetails: IGetShiftDetailsRes | null;
  shiftsForCopy: TGetShiftForCopyItem[] | null;
  scheduleDaily: IGetScheduleDailyRes | null;
  shiftType: TShiftType;
  shouldNotify: boolean;
  error: IErrorRes | IDeleteShiftsError | null;
  isLoading: boolean;
  shiftsIdForDeleting: number[];
  selectedShiftsMode: boolean;
  shiftsIdInViewPort: number | null;
  shouldScrollToCertainItem: boolean;
  canSetStartEndScheduleDates: boolean; // need for correct setting the first day of week in schedule page after change day of week
  emptyJobs: IEmptyJobsForPeriod | [];
}

const initialState: IState = {
  shifts: null,
  totals: null,
  shiftDetails: null,
  shiftsForCopy: null,
  scheduleDaily: null,
  shiftType: ShiftTypeEnum.JOBS,
  shouldNotify: false,
  error: null,
  isLoading: false,
  shiftsIdForDeleting: [],
  selectedShiftsMode: false,
  shiftsIdInViewPort: null,
  shouldScrollToCertainItem: false,
  canSetStartEndScheduleDates: true,
  emptyJobs: [],
};

const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    deleteShiftsForCopy: state => {
      state.shiftsForCopy = null;
    },
    setCanSetStartEndScheduleDates: (state, { payload }: PayloadAction<boolean>) => {
      state.canSetStartEndScheduleDates = payload;
    },
    changeShiftType: (state, { payload }: PayloadAction<TShiftType>) => {
      state.shiftType = payload;
      state.shiftsIdInViewPort = null;
    },
    deleteShiftIdForDeleting: (state, { payload }: PayloadAction<number>) => {
      state.shiftsIdForDeleting = [...state.shiftsIdForDeleting].filter(id => payload !== id);
    },
    addShiftIdForDeleting: (state, { payload }: PayloadAction<number>) => {
      state.shiftsIdForDeleting = [...state.shiftsIdForDeleting, payload];
    },
    addAllShiftsOfWeekForDeleting: state => {
      let allWeekShiftsForDeleting = [] as number[];

      if (state?.shifts) {
        allWeekShiftsForDeleting = state.shifts?.map(({ id }) => id);
      }

      state.shiftsIdForDeleting = [...allWeekShiftsForDeleting];
    },
    clearShiftIdForDeleting: state => {
      state.shiftsIdForDeleting = initialState.shiftsIdForDeleting;
    },
    turnOnSelectedShiftsMode: state => {
      state.selectedShiftsMode = true;
    },
    turnOffSelectedShiftsMode: state => {
      state.selectedShiftsMode = false;
      state.shiftsIdForDeleting = initialState.shiftsIdForDeleting;
    },
    addShiftIdIntoView: (state, { payload }: PayloadAction<number | null>) => {
      state.shiftsIdInViewPort = payload;
    },
    toggleScroll: (state, { payload }: PayloadAction<boolean>) => {
      state.shouldScrollToCertainItem = payload;
      state.shiftsIdInViewPort = payload ? state.shiftsIdInViewPort : null;
    },
    updateShiftOnUpdatingStatusWebSocket: (
      state,
      { payload }: PayloadAction<IGetShiftItemUpdateStatusWebSocket[]>,
    ) => {
      const shiftsWithUpdates = [...payload];
      let newUpdatedShifts = state?.shifts ? [...state?.shifts] : null;

      if (newUpdatedShifts && newUpdatedShifts?.length >= 1) {
        newUpdatedShifts = newUpdatedShifts?.map(oldShiftData => {
          const newExistedShift = shiftsWithUpdates.find(
            newShiftData => oldShiftData?.id === newShiftData?.id,
          );
          return newExistedShift ? { ...oldShiftData, ...newExistedShift } : oldShiftData;
        });
      }

      state.shifts = newUpdatedShifts;
    },
  },
  extraReducers: builder => {
    //* GET EMPTY JOBS FOR SCHEDULE ASYNC THUNK
    builder.addCase(getEmptyJobsForSomePeriodAsync.pending, handleLoadingReducer);
    builder.addCase(getEmptyJobsForSomePeriodAsync.fulfilled, (state, { payload }) => {
      const jobs =
        payload?.items.map(item => ({ ...item, jobName: item?.name, jobId: item?.id })) || [];
      state.isLoading = false;
      state.emptyJobs = {
        count: payload?.count,
        items: jobs,
      };
    });
    builder.addCase(getEmptyJobsForSomePeriodAsync.rejected, handleErrorReducer);
    //* GET SCHEDULE SHIFTS ASYNC THUNK
    builder.addCase(getShiftsAsync.pending, handleLoadingReducer);
    builder.addCase(getShiftsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.shifts = payload?.items;
      state.totals = payload?.totals;
    });
    builder.addCase(getShiftsAsync.rejected, handleErrorReducer);

    //* GET SCHEDULE SHIFTS FOR COPY ASYNC THUNK
    builder.addCase(getShiftsForCopyAsync.pending, state => {
      state.error = null;
    });
    builder.addCase(getShiftsForCopyAsync.fulfilled, (state, { payload }) => {
      state.shiftsForCopy = payload;
    });
    builder.addCase(getShiftsForCopyAsync.rejected, handleErrorReducer);

    //* CREATE SCHEDULE SHIFT ASYNC THUNK
    builder.addCase(createShiftAsync.pending, handleLoadingReducer);
    builder.addCase(createShiftAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(createShiftAsync.rejected, handleErrorReducer);

    //* GET SCHEDULE SHIFT DETAILS ASYNC THUNK
    builder.addCase(getShiftDetailsAsync.pending, handleLoadingReducer);
    builder.addCase(getShiftDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.shiftDetails = payload;
    });
    builder.addCase(getShiftDetailsAsync.rejected, handleErrorReducer);

    //* UPDATE SCHEDULE SHIFT ASYNC THUNK
    builder.addCase(updateShiftAsync.pending, handleLoadingReducer);
    builder.addCase(updateShiftAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(updateShiftAsync.rejected, handleErrorReducer);

    //* DELETE SCHEDULE SHIFT(ONE) ASYNC THUNK
    builder.addCase(deleteShiftAsync.pending, handleLoadingReducer);
    builder.addCase(deleteShiftAsync.fulfilled, state => {
      state.isLoading = false;
      state.shiftDetails = null;
    });
    builder.addCase(deleteShiftAsync.rejected, handleErrorReducer);

    //* DELETE SCHEDULE SHIFTS ASYNC THUNK
    builder.addCase(deleteShiftsAsync.pending, handleLoadingReducer);
    builder.addCase(deleteShiftsAsync.fulfilled, state => {
      state.shiftsIdForDeleting = initialState.shiftsIdForDeleting;
      state.selectedShiftsMode = false;
      state.isLoading = false;
    });
    builder.addCase(deleteShiftsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
      state.shiftsIdForDeleting = payload?.data?.notDeletedShiftsIds || [];
    });

    //* COPY SCHEDULE SHIFT ASYNC THUNK
    builder.addCase(copyShiftToOtherDaysAsync.pending, handleLoadingReducer);
    builder.addCase(copyShiftToOtherDaysAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(copyShiftToOtherDaysAsync.rejected, handleErrorReducer);

    //* COPY SCHEDULE WEEK SHIFTS ASYNC THUNK
    builder.addCase(copyWeekShiftsAsync.pending, handleLoadingReducer);
    builder.addCase(copyWeekShiftsAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(copyWeekShiftsAsync.rejected, handleErrorReducer);

    //* GET SHIFTS FOR NOTIFY EMPLOYEES ASYNC THUNK
    builder.addCase(getShiftsForNotifyAsync.fulfilled, (state, { payload }) => {
      state.shouldNotify = payload?.items
        .filter(({ employeeStatus }) => employeeStatus === 'active')
        .some(({ showInApp, userId, isPastShift }) => userId && !showInApp && !isPastShift);
    });
    builder.addCase(getShiftsForNotifyAsync.rejected, handleErrorReducer);

    //* NOTIFY EMPLOYEES ASYNC THUNK
    builder.addCase(notifyEmployeesAsync.pending, handleLoadingReducer);
    builder.addCase(notifyEmployeesAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(notifyEmployeesAsync.rejected, handleErrorReducer);

    //* GET SCHEDULE SHIFT TIME ENTRIES ASYNC THUNK
    builder.addCase(getShiftTimeEntriesAsync.fulfilled, (state, { payload }) => {
      if (state.shiftDetails) {
        state.shiftDetails.timeEntries = payload;
      }
    });
    builder.addCase(getShiftTimeEntriesAsync.rejected, handleErrorReducer);

    //* GET SCHEDULE SHIFTS DOR 1 DAY ASYNC THUNK (CHART LOGIC DAILY SCHEDULE)
    builder.addCase(getDailyScheduleAsync.pending, handleLoadingReducer);
    builder.addCase(getDailyScheduleAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.scheduleDaily = payload;
    });
    builder.addCase(getDailyScheduleAsync.rejected, handleErrorReducer);

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export const {
  changeShiftType,
  addShiftIdForDeleting,
  deleteShiftIdForDeleting,
  clearShiftIdForDeleting,
  turnOffSelectedShiftsMode,
  turnOnSelectedShiftsMode,
  updateShiftOnUpdatingStatusWebSocket,
  addAllShiftsOfWeekForDeleting,
  addShiftIdIntoView,
  toggleScroll,
  setCanSetStartEndScheduleDates,
  deleteShiftsForCopy,
} = scheduleSlice.actions;

export default scheduleSlice;
