// Core
import { useState } from 'react';
// Packages
import { useHistory } from 'react-router-dom';
// Components
import { Card, GridRow, GridCol, Select, FormItem, Button, Text } from 'components/lib';
// ROUTES
import { ROUTES } from 'router/routes';
// Storage
import webStorage from 'modules/storage';
// Redux
import { useAppDispatch } from 'redux/store';
import { setNewSettingsAfterSwitching } from 'redux/user/userReducer';
import { getCurrentUserAsync } from 'redux/user/userThunk';
import { switchAccountAsync } from 'redux/auth/authThunk';
// API
import { AuthAPI } from 'api/endpoints';
// I18n
import { t } from 'i18n';
// Types
import { IAccount } from 'types/userTypes';
import { TValueType } from 'components/lib/Select';

interface IProps {
  isVerified: boolean;
  id?: number;
  availableAccounts: IAccount[] | null;
  onSetLoading: (flag: boolean) => void;
  onCloseModal: () => void;
}

const SwitchAccount = ({
  isVerified,
  id,
  availableAccounts,
  onCloseModal,
  onSetLoading,
}: IProps) => {
  const history = useHistory();

  const [accountId, setAccountId] = useState<number>();

  const dispatch = useAppDispatch();

  const switchAccount = async () => {
    onCloseModal();
    onSetLoading(true);

    const { refreshToken } = webStorage.getData();

    try {
      await AuthAPI.signOut({ refreshToken });
    } catch (error) {
      console.log(error);
    }

    const actionResultsSwitchAccount = await dispatch(switchAccountAsync(accountId as number));

    if (!switchAccountAsync.fulfilled.match(actionResultsSwitchAccount)) return;

    dispatch(setNewSettingsAfterSwitching(true));

    const actionResultsGetCurrentUser = await dispatch(getCurrentUserAsync());

    if (!getCurrentUserAsync.fulfilled.match(actionResultsGetCurrentUser)) return;

    try {
      const prevStorageData = webStorage.getData();
      webStorage.setData({
        ...prevStorageData,
      });

      history.replace(
        `/${actionResultsSwitchAccount?.payload?.accountName}/${ROUTES.SCHEDULE.split('/')
          .slice(2)
          .join('/')}?shouldPageBeReloaded=true`,
      );

      dispatch(setNewSettingsAfterSwitching(false));
      onSetLoading(false);
      window?.location?.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const options = availableAccounts?.filter(item => item?.id !== id);
  let emptyBlockText = '';

  if (!isVerified) {
    emptyBlockText = t('settingsModal.twoAccountsVerified');
  }

  return (
    <GridRow gutter={32}>
      <GridCol xs={24} xl={12}>
        <Card className="infoCard">
          {isVerified && (
            <GridRow justify="start" gutter={32}>
              <GridCol span={24}>
                <FormItem label={t('settingsModal.switchCompany')}>
                  <Select
                    size="large"
                    onChange={(id: TValueType) => setAccountId(id as number)}
                  >
                    {options?.map(({ id, name, emailVerified }) => (
                      <Select.Option key={id} value={id} disabled={!emailVerified}>
                        {name}{' '}
                        {!emailVerified && `(${t('settingsModal.requiredVerification')})`}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </GridCol>

              {accountId && (
                <GridCol>
                  <Button
                    htmlType="button"
                    type="primary"
                    onClick={switchAccount}
                    disabled={!accountId}
                  >
                    {t('settingsModal.switch')}
                  </Button>
                </GridCol>
              )}
            </GridRow>
          )}

          {!isVerified && <Text> {emptyBlockText}</Text>}
        </Card>
      </GridCol>
    </GridRow>
  );
};

export default SwitchAccount;
