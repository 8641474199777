//Core
import { useState, useCallback } from 'react';
// Components
import SubMenu from './SubMenu';
import SettingsModal from '../SettingsModal';
import { Avatar, Text, GridRow, GridCol, Button, Modal } from 'components/lib';
// Redux
import { STORE_KEYS, useAppDispatch, useAppSelector } from 'redux/store';
import { resendVerificationEmailAsync } from 'redux/user/userThunk';
// Helpers
import getFullNameInitials from 'helpers/getFullNameInitials';
import showNotification from 'helpers/showNotification';
import { ROLES } from 'helpers/data/constants';
// I18n
import { t } from 'i18n';
// Styles
import styles from './authMenu.module.scss';

interface IProps {
  isMultipleAccounts: boolean;
  onSetIsSettingsModalVisible: () => void;
  onToggleMenu?: () => void;
  doFetchAvailableAccounts: () => void;
  onSwitchAccountModal: () => void;
}

const AuthMenu = ({
  isMultipleAccounts,
  onToggleMenu,
  onSetIsSettingsModalVisible,
  doFetchAvailableAccounts,
  onSwitchAccountModal,
}: IProps) => {
  const { user } = useAppSelector(STORE_KEYS.USER);

  const [isSettingModalVisible, setIsSettingsModalVisible] = useState(false);

  const dispatch = useAppDispatch();

  const handleToggleSettingsModal = useCallback(
    () => setIsSettingsModalVisible(prev => !prev),
    [],
  );

  const resendVerificationEmailToken = () => {
    Modal.confirm({
      okType: 'default',
      title: t('header.resendVerificationEmail'),
      okText: t('header.resendLetter'),
      cancelText: t('header.cancel'),
      async onOk() {
        const resultAction = await dispatch(resendVerificationEmailAsync());

        if (resendVerificationEmailAsync.fulfilled.match(resultAction)) {
          showNotification('success', t('header.resendSuccessfully'));
        }
      },
    });
  };

  const userInitials = getFullNameInitials(user);
  let headerAccountInfoText = user?.accountSubscriptionStatus;
  const isBusinessCompany = user?.role === ROLES.business_company;
  const isEmailVerifyButtonShown = user?.isMainBusinessAdmin && !user?.emailVerified;
  const headerTextAccountInfoClassName = !isBusinessCompany ? styles.status : '';

  if (isBusinessCompany) {
    headerAccountInfoText = user?.username;
  }

  return (
    <>
      <GridRow align="middle" justify="start" gutter={4}>
        <GridCol
          xs={{ order: 2, span: 24 }}
          sm={{ order: 2, span: 10 }}
          md={{ order: 2, span: 12 }}
          className={styles.colWithSettings}
        >
          <SubMenu
            isMultipleAccounts={isMultipleAccounts}
            onSetIsSettingsModalVisible={onSetIsSettingsModalVisible}
            onToggleMenu={onToggleMenu}
            doFetchAvailableAccounts={doFetchAvailableAccounts}
            onSwitchAccountModal={onSwitchAccountModal}
          />
        </GridCol>

        <GridCol
          xs={{ order: 1, span: 24 }}
          sm={{ order: 1, span: 14 }}
          md={{ order: 1, span: 12 }}
        >
          <div className={styles.userInfoWrap}>
            <Avatar className={styles.avatar}>{userInitials}</Avatar>

            <div className={styles.fullNameAndRole}>
              <Text fontSize={'middle'}>
                {user?.firstName} {user?.lastName}
              </Text>

              <Text fontSize={'middle'} className={headerTextAccountInfoClassName}>
                {headerAccountInfoText}
              </Text>
            </div>
          </div>
        </GridCol>

        <GridCol span={24} order={1}>
          {isEmailVerifyButtonShown && (
            <Button
              type="link"
              className={styles.verifyEmailButton}
              onClick={resendVerificationEmailToken}
            >
              {t('header.verifyEmail')}
            </Button>
          )}
        </GridCol>
      </GridRow>

      <SettingsModal
        isModalVisible={isSettingModalVisible}
        onCloseModal={handleToggleSettingsModal}
      />
    </>
  );
};

export default AuthMenu;
