// Core
import { ReactNode } from 'react';
// Packages
import {
  FilterValue,
  GetPopupContainer,
  SorterResult,
  SortOrder,
  TableCurrentDataSource,
  TableLocale,
  TableRowSelection,
} from 'antd/lib/table/interface';
import { ColumnsType } from 'antd/lib/table';
import { RenderedCell } from 'rc-table/lib/interface';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { TableProps as RcTableProps } from 'rc-table/lib/Table';
import { SpinProps, Table as AntTable, TablePaginationConfig, TooltipProps } from 'antd';
import cx from 'classnames';
// Components
import TableFooter from 'components/TableFooter';
// Styles
import styles from './table.module.scss';

export type TOnChange<RecordType> = (
  pagination: TablePaginationConfig,
  filters: Record<string, FilterValue | null>,
  sorter: SorterResult<RecordType> | SorterResult<RecordType>[],
  extra: TableCurrentDataSource<RecordType>,
) => void;
export type TTablePaginationConfig = TablePaginationConfig;

export type TRenderItem<RecordType> = (
  value: any,
  record: RecordType,
  index: number,
) => ReactNode | RenderedCell<RecordType>;

interface IProps<RecordType>
  extends Omit<
    RcTableProps<RecordType>,
    | 'transformColumns'
    | 'internalHooks'
    | 'internalRefs'
    | 'data'
    | 'columns'
    | 'scroll'
    | 'emptyText'
  > {
  count?: number;
  dropdownPrefixCls?: string;
  dataSource?: RcTableProps<RecordType>['data'];
  columns?: ColumnsType<RecordType>;
  pagination?: false | TablePaginationConfig;
  loading?: boolean | SpinProps;
  size?: SizeType;
  bordered?: boolean;
  locale?: TableLocale;
  onChange?: TOnChange<RecordType>;
  rowSelection?: TableRowSelection<RecordType>;
  getPopupContainer?: GetPopupContainer;
  scroll?: RcTableProps<RecordType>['scroll'] & {
    scrollToFirstRowOnChange?: boolean;
  };
  sortDirections?: SortOrder[];
  showSorterTooltip?: boolean | TooltipProps;
  rowWithLink?: boolean;
  fixed?: string;
}

// Default pagination settings
export const paginationSettings: TTablePaginationConfig = {
  current: 1,
  pageSize: 25,
  showSizeChanger: true,
  position: ['bottomLeft'],
  pageSizeOptions: ['25', '50', '100'],
};

function Table<RecordType extends object = any>(props: IProps<RecordType>) {
  const {
    count,
    rowKey,
    loading,
    pagination,
    dataSource,
    className,
    columns,
    rowWithLink = false,
    children,
    onChange,
    ...restProps
  } = props;

  const classNames = cx(styles.table, { [styles.tableWithLink]: rowWithLink }, className);
  const footer = () => <TableFooter count={count} />;

  return (
    <AntTable
      showSorterTooltip={false}
      rowKey={rowKey}
      loading={loading}
      pagination={pagination}
      dataSource={dataSource}
      onChange={onChange}
      className={classNames}
      columns={columns}
      footer={count ? footer : undefined}
      {...restProps}
    >
      {children}
    </AntTable>
  );
}

Table.paginationSettings = paginationSettings;

export default Table;
